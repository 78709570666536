<template>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <div class="vx-row flex mb-2 px-5">
        <div class="vx-col w-full" align="right">
          <vs-button
            align="right"
            @click="createHandler"
            icon-pack="feather"
            icon="icon-edit"
          >Add</vs-button>
        </div>
      </div>
      <DiscountList />
    </vs-col>
  </vs-row>
</template>

<script>
import DiscountList from "@/views/components/WebStore/discount/List.vue";

export default {
  components: {
    DiscountList
  },
  data() {
    return {}
  },
  methods: {
    createHandler() {
      this.$router.push({ name: 'superAdminStoreCreateDiscount' })
    },
  }
}
</script>