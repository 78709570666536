<template>
  <vx-card class="discount-list p-5">
    <vs-table
      ref="table"
      :sst="true"
      :data="data"
      @sort="handleSort"
      :max-items="dataTableParams.limit"
      :noDataText="noDataText"
    >
      <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between py-4">
        <vs-select
          placeholder="10"
          vs-multiple
          autocomplete
          :disabled="!serverResponded"
          v-model="dataTableParams.limit"
          class="mr-6"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item,index) in limitOptions"
            :clearable="false"
          />
        </vs-select>
        <vs-input
          icon="search"
          class="search-input"
          :disabled="!serverResponded"
          v-model="dataTableParams.search"
          @input="handleSearch"
          placeholder="Search..."
        />
      </div>

      <template slot="thead">
        <vs-th sort-key="discount_name">Discount Name</vs-th>
        <vs-th sort-key="rule_valid_from">Valid From</vs-th>
        <vs-th sort-key="rule_valid_to">Valid To</vs-th>
        <vs-th>Is Tier Cost</vs-th>
        <vs-th>
          <vx-tooltip text="If switched off, this filter would apply to all clinics" position="top">
            <div class="flex justify-center items-center">
              Clinic
              <vs-icon
                icon="help_outline"
                size="16px"
                bg="#0000"
                round
              ></vs-icon>
              </div>
          </vx-tooltip>
        </vs-th>
        <vs-th>
          <vx-tooltip text="If switched off, this filter would apply to all products" position="top">
            <div class="flex justify-center items-center">
              Product
              <vs-icon
                icon="help_outline"
                size="16px"
                bg="#0000"
                round
              ></vs-icon>
            </div>
          </vx-tooltip>
        </vs-th>
        <vs-th>Group</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].name">
            {{
            data[indextr].discount_name
            }}
          </vs-td>
          <vs-td :data="data[indextr].rule_valid_from">
            {{
            formatDate(data[indextr].rule_valid_from)
            }}
          </vs-td>
          <vs-td :data="data[indextr].rule_valid_to">
            {{
            formatDate(data[indextr].rule_valid_to)
            }}
          </vs-td>
          <vs-td :data="data[indextr].isTierCosting">
            {{
            data[indextr].isTierCosting ? 'Yes' : 'No'
            }}
          </vs-td>
          <vs-td :data="data[indextr].clinic_is_equal">
            <vs-switch
              @click="handleChangeStatus(data[indextr])"
              v-model="data[indextr].clinic_is_equal"></vs-switch>
          </vs-td>
          <vs-td :data="data[indextr].product_is_equal">
            <vs-switch
              @click="handleChangeStatus(data[indextr], 'product')"
              v-model="data[indextr].product_is_equal"></vs-switch>
          </vs-td>
          <vs-td :data="data[indextr].group">
            {{
            data[indextr].group
            }}
          </vs-td>

          <vs-td :data="data[indextr]._id">
            <div class="flex items-center justify-start space-x-2">
              <vx-tooltip text="Update Discount" position="left" >
                <vs-button
                  type="border"
                  size="small"
                  @click="editDetail(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Delete Discount" position="left">
              <vs-button
                type="border"
                size="small"
                color="danger"
                @click="openDeletePopup(data[indextr]._id)"
                icon-pack="feather"
                icon="icon-trash"
              ></vs-button>
            </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup :active.sync="popUpDelete" title="Delete Discount">
      <div class="p-6">
        <p class="text-center text-black text-lg font-medium mb-6">Are you sure you want to delete this discount?</p>
        <div class="flex items-center justify-between space-x-4">
          <vs-button
            size="lg"
            color="primary"
            class="flex-1"
            @click="deleteDetailHandler"
          >Yes</vs-button>
          <vs-button class="flex-1" size="lg" color="danger" @click="popUpDelete = false">Cancel</vs-button>
        </div>
      </div>
    </vs-popup>

    <div class="flex justify-end">
      <span class="mr-2"></span>
      <pagination
        class="mt-3"
        :isFetching="!serverResponded"
        :hasPrev="hasPrev"
        :hasNext="hasNext"
        @handleOnChangePage="handleChangePage"/>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import Pagination from "@/views/general-component/pagination.vue";
import { debounce } from "lodash";
import moment from "moment";

export default {
  name: "DiscountList",
  components: {
    Pagination
  },
  data() {
    return {
      popUpDelete: false,
      popupData: {
        id: '',
      },
      dataTableParams: {
        search: "",
        sort: "_id",
        dir: "desc",
        page: 1,
        limit: 10
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      serverResponded: false,
      hasNext: false,
      hasPrev: false,
      noDataText: 'No discounts available.'
    };
  },
  methods: {
    ...mapActions('discount', [
      "bulkDiscountList",
      "deleteBulkDiscount",
      "updateBulkDiscount"
    ]),
    async fetchList(options = {}) {
      this.data = []
      this.noDataText = "Loading...";

      try {
        const res = await this.bulkDiscountList({
          ...this.dataTableParams,
          ...options
        })
        this.data = res.data.data.docs;
        this.hasNext = res.data.data.hasNext
        this.hasPrev = res.data.data.hasPrev
        this.noDataText = "No discounts available."

        if (options.page) this.dataTableParams.page = options.page
      } catch (err) {
        console.log('Error fetching discounts:: ', err)
      } finally {
        this.serverResponded = true;
      }
    },
    editDetail(id) {
      this.$router.push({
        name: "superAdminStoreEditDiscount",
        params: { discountId: id }
      });
    },
    openDeletePopup(id){
      this.popupData.id = id;
      this.popUpDelete = true;
    },
    handleSearch: debounce(function (searchString) {
      this.serverResponded = false;
      this.$refs.table.currentx = 1;
      this.dataTableParams.search = searchString;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    }, 500),
    handleChangePage(page) {
      let payload = {
        next: true,
        id: this.data[this.data.length - 1]._id,
        page: this.dataTableParams.page + 1
      }

      if (page === 'prev') {
        payload = {
          prev: true,
          id: this.data[0]._id,
          page: this.dataTableParams.page - 1
        }
      }

      this.serverResponded = false;
      this.fetchList(payload)
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    },
    async deleteDetailHandler() {
      try {
        if (this.popupData.id) await this.deleteBulkDiscount(this.popupData.id)

        this.$vs.notify({
          title: "Success",
          text: "Delete discount successfully.",
          color: "success",
        });
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Deletion of discount failed.",
          color: "danger",
        });
      } finally {
        this.serverResponded = false;
        this.popupData.id = "";
        this.popUpDelete = false;

        this.fetchList();
      }
    },
    async handleChangeStatus(selected, type = 'clinic') {
      try {
        const payload = {
          id: selected._id,
          body: {
            product_is_equal: selected.product_is_equal,
            clinic_is_equal: selected.clinic_is_equal,
            discount_name: selected.discount_name,
            rule_valid_from: selected.rule_valid_from,
            rule_valid_to: selected.rule_valid_to,
          }
        }
        payload.body[`${type}_is_equal`] = !selected[`${type}_is_equal`]

        await this.updateBulkDiscount(payload)

        this.$vs.notify({
          title: "Success",
          text: `Discount ${type} status update successfully.`,
          color: "success",
        });
      } catch(err) {
        console.log(err.message)
        this.$vs.notify({
          title: "Failed",
          text: `Updating of discount ${type} status failed.`,
          color: "danger",
        });
      } finally {
        setTimeout(() => {
          this.serverResponded = false;
          this.fetchList();
        }, 500)
      }
    },
    formatDate(dateString) {
      return moment(dateString).format('MM/DD/YYYY')
    }
  },
  watch: {
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    }
  },
  created() {
    this.fetchList();
  }
};
</script>

<style lang="scss">
.discount-list {
  .vx-card__body {
    padding: 0 !important;
  }

  .search-input {
    .vs-input--input {
      border-radius: 20px;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .03);
      }
    }

    .vs-con-input {
      .vs-input--icon {
        top: auto;
        left: 8px;
        border-right: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .discount-list {
    .vx-card .vx-card__collapsible-content .vx-card__body {
      padding: 0;
    }
  }
}
</style>