<template>
  <div class="next-prev-pagination">
    <a href="#"
      :disabled="!hasPrev || isFetching"
      @click="goTo('prev', $event)">&lt;</a>
    <a href="#"
      :disabled="!hasNext || isFetching"
      @click="goTo('next', $event)">&gt;</a>
  </div>
</template>

<script>
export default {
  name: 'next-prev-pagination',
  props: {
    hasNext: {
      type: Boolean,
      required: true,
      default: () => false
    },
    hasPrev: {
      type: Boolean,
      required: true,
      default: () => false
    },
    isFetching: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  methods: {
    goTo(key, e) {
      e.preventDefault();

      if (key === 'next' && !this.hasNext) return;
      if (key === 'prev' && !this.hasPrev) return;

      this.$emit("handleOnChangePage", key)
    }
  },
}
</script>

<style scoped lang="scss">
.next-prev-pagination {
  display: inline-block;
  background: #f0f0f0;
  border-radius: 20px;
  padding: 0 1rem;

  > a {
    color: rgba(0, 0, 0, 0.5);
    float: left;
    padding: 8px 14px;
    text-decoration: none;
    transition: all 0.25s ease;
    cursor: pointer;
    border-radius: 8px;
    -webkit-transition: all 0.25s ease;
    font-weight: 700;

    &[disabled] {
      cursor: not-allowed;
      color: #cdcdcd;
    }
  }
}
</style>